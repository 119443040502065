/* .blog-image img{
    height: 300px;
    width:247px; 
   } */
   .blog-image img{
    z-index: 11;
}
.single-blog{
     /* box-shadow: 0 0 15px 10px rgb(0 0 0 / 6%); */
    border-radius:5px;
    background:#fff; 
    z-index:22;
    margin-bottom: 10px;
    /* padding: 20px 0px; */
}
.single-blog:hover{
 transform:scale(1.01) ;
 /* box-shadow: 1px 0 10px 10px rgb(0 0 0 / 20%); */
 /* border:2px solid #908b8b; */
    border-radius: 10px;
}
.new-product-wrapper{
    padding: 0px 0px;
}
@media only screen and (min-width: 600px){
.blog_img_cover{
    height: 217px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    
}
}
@media only screen and (max-width: 600px){
    .blog_img_cover{
        height: 110px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    
}

.blog-text{
    padding: 2px 2px;
    
    text-align: center;
   
}
.blog-text .title{
    padding:4px 4px;
}
.central_blog_txt{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 20px;
}
.central_blog_txt a{
    padding: 10px 10px;
    top: 50%;
    background-color: #ebb4999e; 
    width: 50%;
  margin: 0 auto;
}
.spotlight{
    background: no-repeat;
    /* background-image:url('../assets/images/bg-1.png'); */
    background-position: center bottom;
    background-size: cover;
    position: relative;
    padding: 100px 0;
    z-index:2;
}

.heading h2:after {
    content: "";
    background: no-repeat;
    /* background-image: url(../assets/images/border-bottom.svg); */
    width: 107px !important;
    height: 5px !important;
    position:absolute;
    left: 0;
    right: 0;
    margin: auto;
    /* bottom: 0; */
    bottom:-10px;
}
.section-title.heading .title{
 color: #9a4f17;
 position: relative;
 
}
.single-blog .blog-text{
    
    margin-bottom: 5px;
    
}
.new-product-wrapper .single-blog{
    /* display : flex;
    flex-direction : row; */
}
.swiper-button-prev{
    background-color: #d75e25;
    padding: 20px 20px;
    border-radius: 50%;
    font-weight: bolder;
    color:white;
}
.swiper-button-next{
    background-color: #d75e25;
    padding: 20px 20px;
    border-radius: 50%;
    font-weight: bolder;
    color:white;
}
:root {
    --swiper-navigation-size: 20px
}
.flag { 
    /* margin-left: 5px;
    margin-bottom: 5px;
    padding-right: 5px; */
    
   /* background: #231f20; position: relative;
    margin-left: 35px;
    color: white;
    line-height: 80px;
    text-align: center; */
  }
  
  /* .flag:after { 
    content: ""; 
    position: absolute; 
    top: 0; 
    right: -35px;
    width: 0; 
    height: 0; 
    border-top: 10px solid #231f20; 
    border-bottom: 10px solid #231f20; 
    border-left: 35px solid transparent;
  
  } */
  .bottom-boder{
    border-bottom:5px solid #d75e25;
  }
  .slider-nav-style-1:hover .swiper-button-next, .slider-nav-style-1:hover .swiper-button-prev{
    opacity: 1;
    visibility: visible;
  }

  /* new horizon slider */
.d-news-hori
  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
.d-news-hori img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
.slider-description-hori{
    padding-left: 5px;
}
