
.sticky-sidebar {
    
    position: fixed;
    bottom: 180px;
    background-color: transparent;
    transform: translateY(50%);
    z-index: 11;
    left:0;
}

.sticky-sidebar ul {
    padding-left: 0;
}

.sticky-sidebar a {
    text-decoration: none;
    color: #333;
    font-size: 10px;
    display: flex;
    align-items: center;
    padding-left:10px;
    padding-top:5px;
}
.floating-icon1{
    width: 55px;
    height: 55px;
    background:#fff;
}
.floating-icon1 img{
    align-items: center;
    width: 50px;
    height: 50px;
}
.floating-icon1 i{
    font-size: 45px;
}
.floating-icon1 .fa-facebook-square{
color: rgb(27, 112, 224);
}
.floating-icon1 .fa-twitter-square{
color: rgb(96, 158, 240);
}

.floating-icon1 .fa-google-plus-square{
color: rgb(224, 27, 27);
}

.floating-icon1 .fa-instagram{
color: rgb(203, 86, 162);
}

.floating-icon1 .fa-linkedin-square{
color: rgb(29, 101, 143);
}

.floating-icon1 .fa-play{
color: rgb(123, 179, 252);
}