
.sub-options {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.7s ease, opacity 0.3s ease;
}
.sub-options.show {
    max-height: 400px; 
    opacity: 1;
    padding-left: 27px;
    margin-top: 10px;
}
.form-check .form-check-input{
    /* margin-left:0px ; */
}
.sub-options .form-check{
    margin-right: 25px;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
}
.awardformdiv{
    /* border: 2px solid black; */
}
.sub-options input[type=file]::file-selector-button{
    /* color: #80adc6; */
    color: #333 ;
    transition: all .2s ease-in-out;
    margin-left: 50%,;
    display: unset;
    align-items: center;
    background-color: #65c1e3;

  }
  .sub-options input[type=file]::file-selector-button:hover{
    background-color: #50b4d8;
  }
  .sub-options input[type=file]{
    color: rgb(230, 230, 230);
    width: 200px;
    
    
  }
  .sub-options {
    border-bottom: 4px solid #172024 ;
    padding-bottom: 5px;
  }
  .suboptionbutton{
    margin: 0 !important;
  }
  .inputdiv{
    text-align: center;
  }
  .inputdiv ._brFile{
    width: 50%;
    color:#fff;
    background: rgb(200 80 82);
    margin-top: 15px;
  }
  