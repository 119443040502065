  /* Full-width image */
  marquee{
    background-color: #a83133;
  }
  marquee:hover{
    animation-play-state: paused;
  }
  .marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 20s linear infinite;
  }

  .marquee:hover span {
    animation-play-state: paused;
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .bhandarkar-notice .modal-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
  }

  /* Position Shop Now button */
  .bhandarkar-notice .shop-now-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .bhandarkar-notice .modal-content{
    height: 270px;
    background-color:#1a1e2b;
  }
  