.footer-bottom .payment-link img{
    /* height: 20px;
    width: 20px; */
}
.footer-area .footer-top .single-wedge .footer-links .single-link:hover {
    padding-left: 10px;
    color:#6c0d19;
}
.footer-area .footer-top .single-wedge .about-text {
    font-size: 15px;
    max-width: 290px;
    line-height: 24px;
}
.footer-area .footer-top .single-wedge .footer-links .single-link {
    font-size: 15px;
}